import React, { useState } from "react";
import { Form } from "react-bootstrap";
import CityData from "../../../../../services/city.service";
import FacilityData from "../../../../../services/facility.service";

const Switch = ({
  type,
  id,
  checked,
  style,
  cityName,
  cityID,
  getMainCityData,
  value,
  data,
}) => {
  const handleSwitchChange = async (e) => {
    const newPublishState = e.target.checked;
    if (value === "city") {
      const response = await CityData.UpdateCityDataRequest(
        cityID,
        cityName,
        newPublishState
      );
      if (response) {
        getMainCityData();
      }
    } else {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      formData.append("isPublish", newPublishState);
      const response = await FacilityData.UpdateFacilityDataRequest(
        cityID,
        formData
      );
      if (response) {
        getMainCityData();
      }
    }
  };
  return (
    <Form.Check
      type={type}
      id={id}
      checked={checked}
      onChange={handleSwitchChange}
      style={style}
    />
  );
};

export default Switch;
