import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import UserListData from "../../../../../services/userList.service";

const CheckboxModal = (props) => {
  const {
    onHide,
    handleSubmitCheckbox,
    checkboxOptionSelect,
    users,
    handleUserID,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [isEnterMouse, setIsEnterMouse] = useState(true);

  const getUserList = async (search) => {
    const response = await UserListData.getUserListData(search, "", "");
    const body = response.data.data
      .map((item) => [
        {
          value: item._id,
          label: item.firstName + " " + item.lastName,
        },
      ])
      .flat();
    setSearchedData(body);
  };

  const handleChangeName = async (e) => {
    setIsEnterMouse(false);
    const input = e.target.value;
    setSearchText(input);
    getUserList(input);
  };

  const playerClick = (player) => {
    setIsEnterMouse(true);
    handleUserID(player.value);
    setSearchText(player.label);
  };

  return (
    <Modal {...props} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>
          {checkboxOptionSelect.value === "AHEAD"
            ? "Book full pitch"
            : checkboxOptionSelect.value === "CANCELLED"
            ? "Cancel Game"
            : "Advertise Game"}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {checkboxOptionSelect.value === "AHEAD" && (
          <div className="pb-3">
            <Form.Label>Select Player</Form.Label>
            {/* <Form.Select
            aria-label="users list"
            onChange={(e) => handleUserID(e.target.value)}
            // value={selectedUser}
          >
            <option>Select Player</option>
            {users?.length &&
              users?.map((res, index) => {
                return (
                  <option key={index} value={res?._id}>
                    {res?.firstName}
                  </option>
                );
              })}
          </Form.Select> */}
            <input
              type="text"
              style={{
                width: "100%",
                borderRadius: "8px",
                outline: "none",
                border: "1px solid",
                padding: "5px 10px",
              }}
              value={searchText}
              onChange={handleChangeName}
              onMouseDown={getUserList}
            />
            {!isEnterMouse && searchedData != "" && (
              <div
                style={{
                  position: "absolute",
                  maxHeight: "300px",
                  background: "#e9e9e9",
                  width: "90%",
                  // paddingBottom: "10px",
                  // paddingTop: "10px",
                  overflow: "auto",
                  borderRadius: "10px",
                  // borderBottomRightRadius: "10px",
                  // borderBottomLeftRadius: "10px",
                  marginTop: "10px",
                  zIndex: 10,
                }}
              >
                {searchedData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        marginBottom: "4px",
                        marginTop: "4px",
                      }}
                      className="user-list"
                      onClick={() => playerClick(item)}
                    >
                      <p className="m-0">{item.label}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        Are you sure you want to{" "}
        {checkboxOptionSelect.value === "AHEAD"
          ? "book full pitch of"
          : checkboxOptionSelect.value === "CANCELLED"
          ? "cancel"
          : "advertise"}{" "}
        this game?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
        <Button
          style={{
            backgroundColor:
              checkboxOptionSelect.value === "AHEAD"
                ? "#3b4b57"
                : checkboxOptionSelect.value === "CANCELLED"
                ? "red"
                : "green",
          }}
          onClick={handleSubmitCheckbox}
        >
          {checkboxOptionSelect.value === "AHEAD"
            ? "Book Full-pitch"
            : checkboxOptionSelect.value === "CANCELLED"
            ? "Cancel Game"
            : "Advertise"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckboxModal;
